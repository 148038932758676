<template>
    <div class="list-filter">
        <div class="card filter-form collapse" id="list-filter">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-xl-2 px-xl-2">
                        <div class="form-group">
                            <label class="text-muted">Ставка</label>
                            <div class="input-group">
                                <input v-model="filter.interestRateFrom"
                                       type="number"
                                       min="0"
                                       :max="filter.interestRateTo || 100"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">&mdash;</div>
                                </div>
                                <input v-model="filter.interestRateTo"
                                       type="number"
                                       :min="filter.interestRateFrom || 0"
                                       max="100"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-xl-2">
                        <div class="form-group">
                            <label class="text-muted">Срок</label>
                            <div class="input-group">
                                <input v-model="filter.initialTermFrom"
                                       type="number"
                                       min="0"
                                       :max="filter.initialTermTo || 36"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">&mdash;</div>
                                </div>
                                <input v-model="filter.initialTermTo"
                                       type="number"
                                       :min="filter.initialTermFrom || 0"
                                       max="36"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">мес.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 col-md-4 col-xl-2 px-xl-2">
                        <div class="form-group">
                            <label class="text-muted">LTV</label>
                            <div class="input-group">
                                <input v-model="filter.ltvFrom"
                                       type="number"
                                       min="0"
                                       :max="filter.ltvTo || 100"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">&mdash;</div>
                                </div>
                                <input v-model="filter.ltvTo"
                                       type="number"
                                       :min="filter.ltvFrom || 0"
                                       max="100"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-7 col-md-6 col-xl-5 px-xl-2">
                        <div class="form-group">
                            <label class="text-muted">Цель займа</label>
                            <div class="input-group">
                                <input-money v-model="filter.loanAmountFrom"
                                             :min="0"
                                             :max="filter.loanAmountTo"
                                             step="1"
                                             class="form-control"
                                             autocomplete="off"/>
                                <div class="input-group-append">
                                    <div class="input-group-text">&mdash;</div>
                                </div>
                                <input-money v-model="filter.loanAmountTo"
                                             :min="filter.loanAmountFrom || 0"
                                             step="1"
                                             class="form-control"
                                             autocomplete="off"/>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="ruble">&#8381;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-xl-2 px-xl-2">
                        <div class="form-group">
                            <label class="text-muted">Доходность</label>
                            <div class="input-group">
                                <input v-model="filter.profitFrom"
                                       type="number"
                                       min="0"
                                       :max="filter.profitFrom || 100"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">&mdash;</div>
                                </div>
                                <input v-model="filter.profitTo"
                                       type="number"
                                       :min="filter.profitTo || 0"
                                       max="100"
                                       step="1"
                                       class="form-control"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <div class="input-group-text">%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xl-2 px-xl-2">
                        <div class="form-group">
                            <label class="text-muted">Сортировка</label>
                            <div class="input-group">
                                <select class="form-control"
                                        autocomplete="off"
                                        @change="handleSelectSorting"
                                >
                                    <option></option>
                                    <option value="profit">Доходность</option>
                                    <option value="interestRate">Ставка</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xl-2 px-xl-2">
                        <div class="form-group">
                            <label class="text-muted">Порядок</label>
                            <div class="input-group">
                                <select class="form-control"
                                        autocomplete="off"
                                        @change="handleSelectOrder"
                                >
                                    <option></option>
                                    <option value="DESC">По убыванию</option>
                                    <option value="ASC">По возрастанию</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-12 px-xl-2 text-right">
                        <label class="d-none d-md-block d-xl-none">&nbsp;</label>
                        <button type="submit" class="btn btn-outline-success float-right ml-1">
                            Фильтровать
                        </button>
                        <button @click="resetFilter" type="submit" class="btn btn-outline-info">
                            Сбросить фильтр
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {InputMoney} from "@/components/common";

export default {
    name: "ListFilter",
    components: {
        InputMoney,
    },
    props: {
        filter: Object
    },
    mounted() {
        this.resetFilter();
    },
    methods: {
        resetFilter() {
            Object.assign(this.filter, {
                interestRateFrom: null,
                interestRateTo: null,
                initialTermFrom: null,
                initialTermTo: null,
                ltvFrom: null,
                ltvTo: null,
                loanAmountFrom: null,
                loanAmountTo: null,
                profitFrom: null,
                profitTo: null
            });
        },
        handleSelectSorting(e){
            this.filter.sorting = e.target.value;
        },
        handleSelectOrder(e){
            this.filter.order = e.target.value;
        }
    }
}
</script>
