<template>
  <div class="buy-back-offers">
    <div class="container-xl">
      <paginated-list :filter="filter"
                      :count="items.length"
                      :total-count="totalCount"
                      :data-loading="dataLoading"
                      @update="loadData"
      >
        <template v-slot:filter>
          <list-filter :filter="filter"/>
        </template>
        <template v-slot:loading>
          <div class="card">
            <div class="card-body">
              <i>Загрузка...</i>
            </div>
          </div>
        </template>
        <template v-slot:no-items>
          <div class="card">
            <div class="card-body">
              <i>Нет доступных предложений</i>
            </div>
          </div>
        </template>
        <div class="card mb-3" v-for="item in items">
          <div class="card-body">
            <div class="row">
              <div class="col-name">
                <div class="h6">{{ item.project.name }}</div>
                <div class="small text-muted">ID {{ item.project.paymentCode }}</div>
              </div>
              <div class="col-buy-back">
                <img src="@/assets/img/bay_back.png" alt="Buy Back">
              </div>
              <div class="col-investment-money">
                <div class="small text-muted">Размер предложения</div>
                <div class="d-inline-block">
                  <money :money="item.investmentMoney"/>
                </div>
                <c-tooltip class="ml-2" text="Можно инвестировать частями"/>
              </div>
              <div class="col-price">
                <div class="small text-muted">Цена</div>
                <div>
                  <money :money="item.price"/>
                </div>
              </div>
              <div class="col-interest-rate">
                <div class="small text-muted">Ставка по договору</div>
                <div>{{ item.project.interestRate }}%</div>
              </div>
              <div class="col-left-months">
                <div class="small text-muted">Оставшийся срок</div>
                <TimeLeftField :left-days="item.leftDays" :left-months="item.leftMonths"/>
              </div>
              <div class="col-ltv">
                <div class="small text-muted">LTV</div>
                <div>{{ item.ltv ? (Math.round(item.ltv * 10) / 10) + "%" : "-" }}</div>
              </div>
              <div class="col-sale-percent">
                <div class="small text-muted">Продаваемая ставка</div>
                <div v-if="item.salePercent">{{ Math.round(item.salePercent * 10) / 10 }}%</div>
                <div v-else>-</div>
              </div>
              <div class="col-actions">
                <router-link :to="{ name: 'buyBackOffer', params: { uuid: item.uuid } }" class="btn btn-success btn-sm">
                  Инвестировать
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </paginated-list>
    </div>
  </div>
</template>

<script>
import { Money, PaginatedList, TimeLeftField, CTooltip } from "@/components/common";
import { createOfferProvider } from "@/utils/di";
import ListFilter from "./ListFilter";

export default {
  name: "BuyBackOffers",
  components: {
    ListFilter,
    Money,
    PaginatedList,
    TimeLeftField,
    CTooltip
  },
  data() {
    return {
      items: [],
      totalCount: 0,
      dataLoading: false,
      filter: {}
    };
  },
  methods: {
    async loadData(page, perPage, { loanAmountFrom, loanAmountTo, ...filter }) {
      this.items = [];
      this.dataLoading = true;

      try {
        const provider = await createOfferProvider();
        const collection = await provider.getListSelling(
            true,
            {
              ...filter,
              loanAmountFrom: loanAmountFrom && loanAmountFrom * 100,
              loanAmountTo: loanAmountTo && loanAmountTo * 100
            },
            page,
            perPage
        );
        this.items = collection.items;
        this.totalCount = collection.totalCount;
      } finally {
        this.dataLoading = false;
      }
    }
  }
}
</script>

<style lang="scss">
.buy-back-offers {
  .card .card-body {
    padding: 0.5rem 1.5rem;
  }

  .col-name,
  .col-buy-back,
  .col-investment-money,
  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-sale-percent,
  .col-actions {
    padding: 1%;
  }

  .col-buy-back,
  .col-investment-money,
  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-sale-percent,
  .col-actions {
    text-align: center;
  }

  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-sale-percent {
    position: relative;
  }

  .col-price:before,
  .col-interest-rate:before,
  .col-left-months:before,
  .col-ltv:before,
  .col-sale-percent:before {
    content: "";
    background: #ecedef;
    position: absolute;
    bottom: 25%;
    left: 0;
    height: 50%;
    width: 0.125rem;
  }

  .col-left-months:before {
    background: none;
  }

  .col-name {
    width: 90%;
  }

  .col-buy-back {
    width: 10%;
  }

  .col-investment-money {
    width: 34%;
  }

  .col-price {
    width: 31%;
  }

  .col-interest-rate {
    width: 35%;
  }

  .col-left-months {
    width: 34%;
  }

  .col-ltv {
    width: 31%;
  }

  .col-sale-percent {
    width: 35%;
  }

  .col-actions {
    width: 100%;
  }

  .col-buy-back img {
    height: 2.5rem;
  }

  @media (min-width: 768px) { // Bootstrap MD
    .col-left-months:before {
      background: #ecedef;
    }

    .col-name {
      width: 90%;
    }

    .col-buy-back {
      width: 10%;
    }

    .col-investment-money {
      width: 18%;
    }

    .col-price {
      width: 18%;
    }

    .col-interest-rate {
      width: 17%;
    }

    .col-left-months {
      width: 17%;
    }

    .col-ltv {
      width: 13%;
    }

    .col-sale-percent {
      width: 17%;
    }

    .col-actions {
      width: 100%;
    }
  }

  @media (min-width: 992px) { // Bootstrap LG
    .col-name {
      width: 21%;
    }

    .col-buy-back {
      width: 5%;
    }

    .col-investment-money {
      width: 12%;
    }

    .col-price {
      width: 10%;
    }

    .col-interest-rate {
      width: 12%;
    }

    .col-left-months {
      width: 11%;
    }

    .col-ltv {
      width: 6%;
    }

    .col-sale-percent {
      width: 12%;
    }

    .col-actions {
      width: 11%;
      padding-top: 1.5rem;
    }

    .col-buy-back img {
      margin-top: 0.75rem;
    }
  }
}
</style>
